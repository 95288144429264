<template>
    <div class="w-100 h-100">
        <div class="w-100 h-100" v-if="content_ready">
            <console-live-page :single="single" @reload="reload"></console-live-page>
        </div>
        <div v-else class="h-100">
            <play-error-page v-if="error" :single="single" :error="error"></play-error-page>
            <div v-else class="flex-in-middle h-100" style="font-size: 28px;">
                <i class="el-icon-loading"></i>
                <span class="text-muted ml-3">{{ $t("MESSAGE.WAIT") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import PlayErrorPage from "@/components/play-error-page";
import ConsoleLivePage from "../../components/console-live-mobile-page.vue";
import ConsoleMixin from "../mixins/console-mixin";

export default {
    components: {ConsoleLivePage, PlayErrorPage},
    mixins: [ConsoleMixin],
}
</script>

